// import NavBar from './NavBar';
import TopBar from './TopBar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Overview from './overview';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 10;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex'
  },
  main: {
    flexGrow: 1,
    // minHeight: '100%',
    // overflow: 'auto',
    // paddingTop: APP_BAR_MOBILE + 40,
    // paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      // paddingTop: APP_BAR_DESKTOP + 5,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.down('xl')]: {
      // paddingTop: APP_BAR_DESKTOP + 5,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: APP_BAR_MOBILE+5 ,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
    // [theme.breakpoints.down('md')]: {
    //   paddingTop: APP_BAR_DESKTOP + 5,
    //   paddingLeft: theme.spacing(2),
    //   paddingRight: theme.spacing(2)
    // }
  }
}));

// ----------------------------------------------------------------------

AppLayout.propTypes = {
  children: PropTypes.node
};

function AppLayout({ children }) {
  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onOpenNav={() => setOpenNav(true)} />
      {/* <NavBar onCloseNav={() => setOpenNav(false)} isOpenNav={openNav} /> */}
      {/* <Overview /> */}
      <div className={classes.main}>{children}</div>
    </div>
  );
}

export default AppLayout;
